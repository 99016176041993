import React from "react"

// Data
import siteContent from "../content/siteContent"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Subnavigation from "../components/Shared/Subnavigation"
import Article from "../components/Shared/Article"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import Content from "../components/Shared/Content"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import ShareLinks from "../components/Shared/ShareLinks"
import ArticleHeader from "../components/Shared/ArticleHeader"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"

const TemplateContentPageWithSubnavigation = () => (
    <Layout venue={siteContent.currentVenue} currentLocation="Support us">
        <HeroAreaSmall title="404 Error"></HeroAreaSmall>

        <Main>
            <ContentWithSidebar>
                <Content>
                    <ArticleHeader
                        title="We couldn't find the page you were looking for"
                        introduction={`It's possible that someone has linked to it from elsewhere and our site has changed. If you think this is a mistake, please get in touch.`}
                    />
                </Content>

                <Sidebar>{/* <Subnavigation/> */}</Sidebar>
            </ContentWithSidebar>
        </Main>
    </Layout>
)

export default TemplateContentPageWithSubnavigation
